import React from "react"
import { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { ChainId, DAppProvider, useEtherBalance, useEthers, useContractFunction, useTokenBalance, useContractCall, useContractCalls } from '@usedapp/core'
import { formatEther } from '@ethersproject/units'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { getContractData, getContractAddress } from "../utils/contract.js"

export default function GalleryDapp(props) {

  const GalleryLoadingIndicator = props => {
    return (
      <div style={{
          marginTop: "15px",
          width: "100%",
          height: "25",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Loader type="BallTriangle" color="#44615a" height="25" width="25" />
      </div>
    );  
  }
  const mmImagesBaseUrl = process.env.GATSBY_IMAGES_URL

  const { activateBrowserWallet, account, library } = useEthers()

  const mmInterface = new utils.Interface(getContractData().abi)
  const mmContractAddress = getContractAddress()
  const mmContract = new Contract(mmContractAddress, mmInterface)
  //const { state:balanceOfState, send:balanceOfSend } = useContractFunction(mmContract, 'balanceOf', { transactionName: 'BalanceOf' })
  //const { state:tokenOfOwnerByIndexState, send:tokenOfOwnerByIndexSend } = useContractFunction(mmContract, 'tokenOfOwnerByIndex', { transactionName: 'TokenOfOwnerByIndex' })

  const [connectDelaying, setConnectDelaying] = useState(true);
  const [allTokenIdCalls, setAllTokenIdCalls ] = useState([]);
  const [allTokenIdNameCalls, setAllTokenIdNameCalls ] = useState([]);
  const [tokenIdToNameMap, setTokenIdToNameMap ] = useState({});
  const [bannerOpen, setBannerOpen] = useState(0);
  const [bannerContent, setBannerContent] = useState(0);

  setTimeout(() => {
    setConnectDelaying(false)
  }, 300);

  const walletTokenBalance = useTokenBalance(mmContractAddress, account)
  const walletTokensResults = useContractCalls(allTokenIdCalls)
  const walletTokensNameResults = useContractCalls(allTokenIdNameCalls)

  

  const onWalletActivationError = (error) => {
    console.log(error.message)
    setBannerContent(error.message)
    setBannerOpen(true)
  }

  const buildWalletTokenCalls = (tokensInWallet) => {
    var allTokenIdCalls = []
    for (let i = 0; i < tokensInWallet; i++) { 
      allTokenIdCalls.push(
        {
          abi: mmInterface, // ABI interface of the called contract
          address: mmContractAddress, // On-chain address of the deployed contract
          method: 'tokenOfOwnerByIndex', // Method to be called
          args: [account, i]
        }
      )
    }
    
    return allTokenIdCalls
  }

  const buildWalletTokenNameCalls = (walletTokens) => {
    var allTokenIdNameCalls = []
    for (let i = 0; i < walletTokens.length; i++) { 
      if (walletTokens[i] !== undefined) {
        allTokenIdNameCalls.push(
          {
            abi: mmInterface, // ABI interface of the called contract
            address: mmContractAddress, // On-chain address of the deployed contract
            method: 'viewName', // Method to be called
            args: [walletTokens[i][0]]
          }
        )
      }
    }
    
    return allTokenIdNameCalls
  }



  useEffect(() => {

    if (walletTokenBalance && parseInt(utils.formatUnits(walletTokenBalance, 0)) > 0) {
      const tokenBalance = parseInt(utils.formatUnits(walletTokenBalance, 0))
      setAllTokenIdCalls(buildWalletTokenCalls(tokenBalance))
    }

    if (walletTokensResults) {
      setAllTokenIdNameCalls(buildWalletTokenNameCalls(walletTokensResults))
    }

    if (walletTokensNameResults) {
      let tokenIdToNameMap = {}
      for (let i = 0; i < walletTokensNameResults.length; i++) {
        if (walletTokensNameResults[i] !== undefined) {
          tokenIdToNameMap[walletTokensResults[i][0]] = walletTokensNameResults[i][0];
        }
      }
      setTokenIdToNameMap(tokenIdToNameMap)
    }

  }, [walletTokenBalance, walletTokensResults, walletTokensNameResults])
    


  return (
    
    <div class="dapp">

      {!account && 
      <div class={bannerOpen ? "open banner-container" : "closed banner-container"}>
        <div class="banner-padding">
          <div class="banner-close-button" role="button" tabIndex={0} aria-label="close" onKeyDown={() => setBannerOpen(false)} onClick={() => setBannerOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></div>
          <div class="banner-content">{bannerContent}</div>
        </div>
      </div>}

      {!account && 
      <div class={(!account && !connectDelaying) ? "connect-container open" : "connect-container"}>
        <p class="connect-text">While using a browser-based wallet (ie: MetaMask), click "Connect Wallet" below to view your Moody Monstera!</p>
        <div class={account && "button disabled" || "button"} role="button" disabled={account} onClick={() => activateBrowserWallet(onWalletActivationError)} onKeyDown={() => activateBrowserWallet(onWalletActivationError)}>
          <div class="button-text">{(account && "Connected!") || "Connect Wallet"}</div>
        </div>
      </div>}

      <div class={account ? "did-connect-container open" : "did-connect-container"}>
        <div class={bannerOpen ? "open banner-container" : "closed banner-container"}>
          <div class="banner-padding">
            <div class="banner-close-button" role="button" tabIndex={0} aria-label="close" onKeyDown={() => setBannerOpen(false)} onClick={() => setBannerOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></div>
            <div class="banner-content">{bannerContent}</div>
          </div>
        </div>
        <p class="wallet-text">Your Address is:<br/>{account}</p>
        <p>You own <b>{(walletTokenBalance && utils.formatUnits(walletTokenBalance, 0)) || "0"}</b> Moody Monsteras!</p>
        <p class="wallet-text-sm">Monsteras are revealed when minted, but could take a few seconds to update. If your Monsteras haven't revealed yet, wait a minute and try refreshing the page.</p>
      </div>
      

      {walletTokensResults && walletTokensResults[0] && walletTokensResults.map(tokenId => (
        <div class="mm-container" key={utils.formatUnits(tokenId[0], 0)} id={'token-'+utils.formatUnits(tokenId[0], 0)}>
          <img class="mm-image" src={mmImagesBaseUrl+"/"+utils.formatUnits(tokenId[0], 0)+".png"}/>
          <p class="mm-name">Moody Monstera #{utils.formatUnits(tokenId[0], 0)}<br/>
          <span class="mm-custom-name">{tokenIdToNameMap[tokenId[0]] !== '' ? tokenIdToNameMap[tokenId[0]] : "Unnamed Monstera"}</span></p>
        </div>
      ))}
      
    </div>
  )
}
