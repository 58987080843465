import React from "react"
import { Link } from "gatsby"
import { ChainId, DAppProvider, useEtherBalance, useEthers } from '@usedapp/core'
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import Loader from 'react-loader-spinner';
import Layout from "../components/layout"
import Api from "../utils/api.js"
import GalleryDapp from "../components/gallery_dapp"

const api = new Api()

const dAppConfig = {
}

export default class Gallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
  }

  reload() {
  }

  render() { return (
    <Layout>

      {/* <div class="gallery-soon-container">
        <div class="gallery-soon">
          <div class="gallery-soon-text">Gallery Available on Oct 31!</div>
        </div>
      </div> */}

      <DAppProvider config={dAppConfig}>
        <GalleryDapp />
      </DAppProvider>

    </Layout>
  )}
}


